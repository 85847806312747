import { useContext } from 'react'
import {
  Button,
  Divider,
  FullScreen,
  Grid,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import PageHeader from '../../../components/common/PageHeader'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { confirmDunsUpdate } from '../../../store/Internal/actionCreator'
import { useNavigate } from 'react-router-dom'
import { useChipComponent } from '../../Home/HomeComponent/Chip/useChipComponent'
import { ThemeContext } from '../../../components/Layout/Layout'

const EditDunsResponse = (props) => {
  const isDarkMode = useContext(ThemeContext)
  const history = useNavigate()
  const { id } = useParams()

  const dunsReviewData = useSelector((state) => {
    const { internal } = state
    const { editDunsList } = internal
    return editDunsList
  })

  var currentSupplierData = dunsReviewData?.data?.filter((elem) => {
    return elem.supplier_id === Number(id)
  })

  const dispatch = useDispatch()

  const updateDunsStatus = (supplierId, isApproved) => {
    confirmDunsUpdate(supplierId, isApproved, dispatch)
      .then(() => {
        props.handleModalVisibility()
      })
      .catch(() => {})
  }

  const { usCurrency } = useChipComponent()

  if (currentSupplierData?.length > 0) {
    currentSupplierData = currentSupplierData[0]
  }

  let { cssStyleCaption } = isDarkMode ? 'duns-caption-dark' : 'duns-caption'
  let cssStyleText = isDarkMode ? 'duns-text-dark' : 'duns-text'
  return (
    <FullScreen className="fullScreen sm-full-screen-zindex" isVisible={true}>
      <PageHeader
        title={'Review Duns Edit'}
        onClose={() => {
          history(-1)
        }}
      />
      <>
        <Grid.Container
          className={`${
            isDarkMode ? 'ag-theme-balham-dark' : 'ag-theme-balham'
          }`}
          style={{ width: '100%', paddingLeft: '20px' }}
        >
          <Grid.Item style={{ width: '45%', paddingLeft: '20px' }}>
            <h3>Current Supplier Profile</h3>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '5vh' }}
            >
              Supplier Id
              <div className={cssStyleText}>
                {currentSupplierData?.supplier_id}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              Legal Name
              <div className={cssStyleText}>
                {currentSupplierData?.old_profile?.legal_name}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              HQ Address
            </Input.Label>
            <Grid.Container style={{ paddingLeft: '10px' }}>
              <Input.Label style={{ width: '40%', marginTop: '2vh' }}>
                AdressLine1
                <div className={cssStyleText}>
                  {currentSupplierData?.old_profile?.address_line1}
                </div>
              </Input.Label>
              <Input.Label style={{ width: '40%', marginTop: '2vh' }}>
                AdressLine2
                <div className={cssStyleText}>
                  {currentSupplierData?.old_profile?.address_line2}
                </div>
              </Input.Label>
            </Grid.Container>
            <Grid.Container style={{ paddingLeft: '10px' }}>
              <Input.Label style={{ width: '40%', marginTop: '4vh' }}>
                City
                <div className={cssStyleText}>
                  {currentSupplierData?.old_profile?.street}
                </div>
              </Input.Label>
              <Input.Label style={{ width: '40%', marginTop: '4vh' }}>
                State
                <div className={cssStyleText}>
                  {currentSupplierData?.old_profile?.region}
                </div>
              </Input.Label>
            </Grid.Container>
            <Grid.Container style={{ paddingLeft: '10px' }}>
              <Input.Label style={{ width: '40%', marginTop: '4vh' }}>
                Country
                <div className={cssStyleText}>
                  {currentSupplierData?.old_profile?.country}
                </div>
              </Input.Label>
              <Input.Label style={{ width: '40%', marginTop: '4vh' }}>
                Postal Code
                <div className={cssStyleText}>
                  {currentSupplierData?.old_profile?.postal_code}
                </div>
              </Input.Label>
            </Grid.Container>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              DUNs
              <div className={cssStyleText}>
                {currentSupplierData?.old_profile?.duns_id}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              Company Type
              <div className={cssStyleText}>
                {currentSupplierData?.old_profile?.company_type}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              Stock Symbol
              <div className={cssStyleText}>
                {currentSupplierData?.old_profile?.stock}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              Website Address
              <div className={cssStyleText}>
                {currentSupplierData?.old_profile?.website_address}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              Telephone Number
              <div className={cssStyleText}>
                {currentSupplierData?.old_profile?.telephone}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              Year Started
              <div className={cssStyleText}>
                {currentSupplierData?.old_profile?.started}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              Employees
              <div className={cssStyleText}>
                {currentSupplierData?.old_profile?.employees}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              Annual Revenue
              <div className={cssStyleText}>
                {usCurrency(
                  currentSupplierData?.old_profile?.annual_revenue,
                  currentSupplierData?.old_profile?.currency
                ) || '-'}
              </div>
            </Input.Label>
          </Grid.Item>

          <Divider className="duns-divider" direction="vertical" />
          <Grid.Item style={{ width: '45%', paddingLeft: '20px' }}>
            <h3>Requested Supplier Profile</h3>

            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '5vh' }}
            >
              Supplier Id
              <div className={cssStyleText}>
                {currentSupplierData?.supplier_id}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              {' '}
              Legal Name
              <div className={cssStyleText}>
                {currentSupplierData?.new_profile?.legal_name}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              HQ Address
            </Input.Label>
            <Grid.Container style={{ paddingLeft: '10px' }}>
              <Input.Label style={{ width: '40%', marginTop: '2vh' }}>
                AdressLine1
                <div className={cssStyleText}>
                  {currentSupplierData?.new_profile?.address_line1}
                </div>
              </Input.Label>
              <Input.Label style={{ width: '40%', marginTop: '2vh' }}>
                AdressLine2
                <div className={cssStyleText}>
                  {currentSupplierData?.new_profile?.address_line2}
                </div>
              </Input.Label>
            </Grid.Container>
            <Grid.Container style={{ paddingLeft: '10px' }}>
              <Input.Label style={{ width: '40%', marginTop: '4vh' }}>
                City
                <div className={cssStyleText}>
                  {currentSupplierData?.new_profile?.street}
                </div>
              </Input.Label>
              <Input.Label style={{ width: '40%', marginTop: '4vh' }}>
                State
                <div className={cssStyleText}>
                  {currentSupplierData?.new_profile?.region}
                </div>
              </Input.Label>
            </Grid.Container>
            <Grid.Container style={{ paddingLeft: '10px' }}>
              <Input.Label style={{ width: '40%', marginTop: '4vh' }}>
                Country
                <div className={cssStyleText}>
                  {currentSupplierData?.new_profile?.country}
                </div>
              </Input.Label>
              <Input.Label style={{ width: '40%', marginTop: '4vh' }}>
                Postal Code
                <div className={cssStyleText}>
                  {currentSupplierData?.new_profile?.postal_code}
                </div>
              </Input.Label>
            </Grid.Container>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              DUNs
              <div className={cssStyleText}>
                {currentSupplierData?.new_profile?.duns_id}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              Company Type
              <div className={cssStyleText}>
                {currentSupplierData?.new_profile?.company_type}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              Stock Symbol
              <div className={cssStyleText}>
                {currentSupplierData?.new_profile?.stock}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              Website Address
              <div className={cssStyleText}>
                {currentSupplierData?.new_profile?.website_address}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              Telephone Number
              <div className={cssStyleText}>
                {currentSupplierData?.new_profile?.telephone}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              Year Started
              <div className={cssStyleText}>
                {currentSupplierData?.new_profile?.year_started}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              Employees
              <div className={cssStyleText}>
                {currentSupplierData?.new_profile?.employees}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              Annual Revenue
              <div className={cssStyleText}>
                {usCurrency(
                  currentSupplierData?.new_profile?.annual_revenue,
                  currentSupplierData?.new_profile?.currency
                ) || '-'}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '8vh' }}
            >
              Requested By
              <div className={cssStyleText}>
                {currentSupplierData?.requested_by}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              Supplier Name
              <div className={cssStyleText}>
                {currentSupplierData?.supplier_name}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              Requested Time
              <div className={cssStyleText}>
                {currentSupplierData?.updated_timestamp}
              </div>
            </Input.Label>
            <Input.Label
              className={cssStyleCaption}
              style={{ marginTop: '2vh' }}
            >
              Reason
              <div className={cssStyleText}>{currentSupplierData?.reason}</div>
            </Input.Label>
          </Grid.Item>
        </Grid.Container>

        <Grid.Container style={{ marginTop: '5vh' }}>
          <Grid.Item style={{ width: '78%' }} />
          <Grid.Item style={{ width: '8%' }}>
            <Button
              type="secondary"
              className="hc-mr-normal"
              onClick={() => {
                updateDunsStatus(currentSupplierData?.supplier_id, false)
                history(`/internal/duns-match-2`)
              }}
            >
              Decline
            </Button>
          </Grid.Item>
          <Grid.Item style={{ width: '14%' }}>
            <Button
              type="primary"
              className="hc-mr-normal"
              onClick={() => {
                updateDunsStatus(currentSupplierData?.supplier_id, true)
                history(`/internal/duns-match-2`)
              }}
            >
              Approve
            </Button>
          </Grid.Item>
        </Grid.Container>
      </>
    </FullScreen>
  )
}

export default EditDunsResponse
