import axios from 'axios'
import * as ACTIONS from './actionType'
import apiConfig from '../../config/apiConfig'
import { Notification } from '../Notification/actionCreator'

export function getSupplierLocation(supplierId) {
  const url = `${apiConfig.api.location}external_location_dashboards/${supplierId}`
  return async (dispatch) => {
    dispatch({ type: ACTIONS.TYPE.SUPPLIER_LOCATION_INIT })
    await axios
      .get(url)
      .then((response) => {
        //const { data } = response
        const { data } = response
        dispatch({ type: ACTIONS.TYPE.SUPPLIER_LOCATION_INFO, data })
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.TYPE.SUPPLIER_LOCATION_FAIL, error })
      })
  }
}

export function fetchDeptsWithNoShippingLocation(supplierId) {
  const url = `${apiConfig.api.external}department_shippings/${
    supplierId ? `?supplier_id=${supplierId}` : ''
  }`
  return async (dispatch) => {
    dispatch({
      type: ACTIONS.TYPE.FETCH_DEPARTMENTS_WITH_NO_SHIPPING_LOCATION_INIT,
    })
    await axios
      .get(url)
      .then((response) => {
        const { data } = response
        dispatch({
          type: ACTIONS.TYPE.FETCH_DEPARTMENTS_WITH_NO_SHIPPING_LOCATION_INFO,
          data,
        })
      })
      .catch((error) => {
        dispatch({
          type: ACTIONS.TYPE.FETCH_DEPARTMENTS_WITH_NO_SHIPPING_LOCATION_FAIL,
          error,
        })
      })
  }
}

export function getSupplierContact(supplierId, shouldAccessBeGiven) {
  if (supplierId) {
    const url = `${apiConfig.api.contact}dashboard_contacts?supplier_id=${supplierId}`
    return function (dispatch, getState) {
      const { supplier } = getState()
      if (supplier?.contactInfo?.loading || supplier?.contactInfo?.error) {
        return
      }

      dispatch({ type: ACTIONS.TYPE.SUPPLIER_CONTACT_INIT })

      dispatch(fetchAssignRoles(supplierId, shouldAccessBeGiven))

      return axios
        .get(url)
        .then((response) => {
          const data = response.data
          dispatch({ type: ACTIONS.TYPE.SUPPLIER_CONTACT_INFO, data })
        })
        .catch((error) => {
          dispatch({ type: ACTIONS.TYPE.SUPPLIER_CONTACT_FAIL, error })
        })
    }
  }
}
export function getSupplierContactFromVMM(supplierId) {
  if (supplierId) {
    const url = `${apiConfig.api.aggregator}supplierContacts/${supplierId}`
    return function (dispatch) {
      return axios
        .get(url)
        .then((response) => {
          const data = response.data
          dispatch({ type: ACTIONS.TYPE.SUPPLIER_CONTACT_INFO, data })
        })
        .catch((error) => {
          dispatch({ type: ACTIONS.TYPE.SUPPLIER_CONTACT_FAIL, error })
        })
    }
  }
}

export function getSupplierContactReview(reqBody) {
  const url = `${apiConfig.api.contact}biannual_reviews`

  return function (dispatch) {
    return axios
      .put(url, reqBody)
      .then((response) => {
        const data = response?.data?.contact_volist
        dispatch({ type: ACTIONS.TYPE.SUPPLIER_CONTACT_REVIEW_INFO, data })
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.TYPE.SUPPLIER_CONTACT_REVIEW_FAIL, error })
      })
  }
}

export function deleteContact(contactStatusDTO, supplierId) {
  return function (dispatch) {
    return axios
      .post(`${apiConfig.api.contact}contact_status_changes`, contactStatusDTO)
      .then((response) => {
        const errorArray = response?.data?.map((item) => item.error)
        if (errorArray?.some((item) => item === false)) {
          const reqBody = {
            supplier_id: supplierId,
          }
          dispatch(getSupplierContactReview(reqBody))
          dispatch(
            Notification(
              true,
              'Your request is being processed.  It may take some time depending upon the size of your request. Refresh the page to view updates.',
              false,
              '',
              '',
              50000
            )
          )
        } else {
          dispatch(
            Notification(
              true,
              'Sorry, Request Failed.Please contact admin',
              true
            )
          )
        }
      })
  }
}

export function keepContact(userIds, supplierId) {
  return function (dispatch) {
    return axios
      .put(`${apiConfig.api.contact}keep_contacts/${supplierId}`, userIds)
      .then((response) => {
        if (response?.data?.result === 'Success') {
          const reqBody = {
            supplier_id: supplierId,
          }
          dispatch(getSupplierContactReview(reqBody))
          dispatch(Notification(true, 'Your Changes are saved'))
        } else {
          dispatch(
            Notification(
              true,
              'Sorry, Request Failed.Please contact admin',
              true
            )
          )
        }
      })
  }
}

export function resetContact(dispatch) {
  dispatch({ type: ACTIONS.TYPE.SUPPLIER_CONTACT_RESET })
}
export function resetLocation(dispatch) {
  dispatch({ type: ACTIONS.TYPE.SUPPLIER_LOCATION_RESET })
}
export function resetSupplier(dispatch) {
  dispatch({ type: ACTIONS.TYPE.SUPPLIER_RESET })
}

export function getTargetSetup(dispatch, supplierId) {
  const url = `${apiConfig.api.supplier}setups/${supplierId}`
  return function (dispatch) {
    dispatch({ type: ACTIONS.TYPE.TARGET_SETUP_INIT })
    return axios
      .get(url)
      .then((response) => {
        const data = response.data
        dispatch({ type: ACTIONS.TYPE.TARGET_SETUP_INFO, data })
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.TYPE.TARGET_SETUP_FAIL, error })
      })
  }
}

export function resetTargetSetup(dispatch) {
  dispatch({ type: ACTIONS.TYPE.TARGET_SETUP_RESET })
}

export function fetchUserContact(supplierId) {
  const url = `${apiConfig.api.contact}search`

  return axios.post(url, { supplier_id: supplierId })
}

export function updateAssignRoles(data) {
  return {
    type: ACTIONS.TYPE.UPDATE_ASSIGN_ROLES,
    payload: data,
  }
}

export function saveUserContact(reqData) {
  const url = `${apiConfig.api.contact}contact_responsibilities/`

  return axios.post(url, reqData)
}

export function fetchAssignRoles(supplierId, shouldFetch) {
  const url = `${apiConfig.api.contact}todos?supplier_id=${supplierId}`
  return function (dispatch, getState) {
    if (!shouldFetch) {
      return
    }

    return axios
      .get(url)
      .then((response) => {
        const data = response?.data

        const modifiedAssignedRoles = {
          ...data,
          responsibilities: data?.responsibilities?.map((firstLevel) => {
            return {
              ...firstLevel,
              responsibility_contacts: firstLevel.responsibility_contacts.map(
                (secondLevel) => {
                  if (secondLevel.contact_vos?.length) {
                    return {
                      ...secondLevel,
                      contact_vos: secondLevel.contact_vos.map((contact) => ({
                        id: contact.contact_id,
                        value: `${contact.first_name} ${contact.last_name}`,
                        label: `${contact.first_name} ${contact.last_name}`,
                      })),
                    }
                  }

                  return {
                    ...secondLevel,
                    contact_vos: [],
                  }
                }
              ),
            }
          }),
        }

        dispatch({
          type: ACTIONS.TYPE.FETCH_ASSIGN_ROLES,
          data: modifiedAssignedRoles,
        })

        return Promise.resolve(modifiedAssignedRoles)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}

export const fetchShippingDetails = async (supplierId, locationId) => {
  try {
    const response = await axios.get(
      `${apiConfig.api.location}new-shipping_details/${locationId}?supplier_id=${supplierId}`
    )

    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchSupplierDocuments = (supplierId) => {
  const url = `${apiConfig.api.aggregator}supplier_documents/${supplierId}`
  return async (dispatch) => {
    dispatch({
      type: ACTIONS.TYPE.FETCH_SUPPLIER_DOCUMENT,
      payload: {
        loading: true,
      },
    })
    await axios
      .get(url)
      .then((response) => {
        dispatch({
          type: ACTIONS.TYPE.FETCH_SUPPLIER_DOCUMENT,
          payload: {
            loading: false,
            data: response.data,
            error: false,
          },
        })
      })
      .catch((error) => {
        dispatch({
          type: ACTIONS.TYPE.FETCH_SUPPLIER_DOCUMENT,
          payload: {
            loading: false,
            error: true,
          },
        })
      })
  }
}

export const resetDocuments = () => {
  return { type: ACTIONS.TYPE.RESET_SUPPLIER_DOCUMENT }
}

export const saveCoi = async (data, supplierId) => {
  try {
    const response = await axios.post(
      `${apiConfig.api.supplier}coi_documents/${supplierId}`,
      data
    )

    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const editCoi = async (data, supplierId) => {
  try {
    const response = await axios.put(
      `${apiConfig.api.supplier}coi_documents/${supplierId}`,
      data
    )

    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchRhinoDoc = async (rhinoId, type, cancelToken) => {
  try {
    const response = await axios.get(
      `${apiConfig.api.supplier}documents?${
        rhinoId ? `rhinoRecordId=${rhinoId}&` : ''
      }type=${type}`,
      {
        cancelToken: cancelToken?.token || '',
      }
    )

    return Promise.resolve(response?.data?.jsonByte)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const sendPOLEmailSign = async (data, supplierId) => {
  try {
    const response = await axios.post(
      `${apiConfig.api.supplier}pol_documents/${supplierId}`,
      data
    )

    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const resendPOLEmail = async (supplierId) => {
  try {
    const response = await axios.get(
      `${apiConfig.api.supplier}pol_documents/reminders/${supplierId}`
    )

    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const cancelPOLEmail = async (supplierId) => {
  try {
    const response = await axios.put(
      `${apiConfig.api.supplier}pol_documents/cancellations/${supplierId}`
    )

    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const removePOLRequirement = async (supplierId, data) => {
  try {
    const response = await axios.put(
      `${apiConfig.api.supplier}pol_documents/exceptions/${supplierId}`,
      data
    )

    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchProgramAndCertTypes = async (countryCode) => {
  try {
    const programTypesEndpoint = axios.get(
      `${apiConfig.api.masterData}program_types?country_codes=${countryCode}`
    )

    const certTypesEndpoint = axios.get(
      `${apiConfig.api.masterData}certification_types?country_codes=${countryCode}`
    )

    const response = await Promise.all([
      programTypesEndpoint,
      certTypesEndpoint,
    ])

    return Promise.resolve({
      programTypes: response?.[0]?.data,
      certTypes: response?.[1]?.data,
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

export const saveSupplyChainCertExt = async (
  data,
  supplierId,
  freshlyAddedProgram
) => {
  try {
    let response
    const apiUrl = `${apiConfig.api.supplier}supply_chain_certs/${supplierId}`

    if (freshlyAddedProgram) {
      response = await axios.post(apiUrl, data)
    } else {
      response = await axios.put(apiUrl, data)
    }

    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const saveSupplyChainCertInt = async (
  data,
  supplierId,
  freshlyAddedProgram
) => {
  try {
    let response
    const apiUrl = `${apiConfig.api.supplier}supply_chain_certs_internals/${supplierId}`

    if (freshlyAddedProgram) {
      response = await axios.post(apiUrl, data)
    } else {
      response = await axios.put(apiUrl, data)
    }
    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const saveSupplyChainCertLocation = async (data, locationId) => {
  try {
    let response
    const apiUrl = `${apiConfig.api.location}supply_chain_certs_internals/${locationId}`

    response = await axios.put(apiUrl, data)

    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchSupplierLocations = async (locationId) => {
  try {
    const response = await axios.get(
      `${apiConfig.api.location}location_suppliers/${locationId}`
    )

    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const createPolAgreementContact = async (data) => {
  try {
    const response = await axios.post(`${apiConfig.api.contact}contacts`, data)

    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchPolContactData = async (data) => {
  try {
    const response = await axios.post(
      `${apiConfig.api.aggregator}contacts`,
      data
    )

    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchContactAuditData = async (
  contactId,
  supplierId,
  cancelToken
) => {
  try {
    const response = await axios.get(
      `${apiConfig.api.contact}audits/${contactId}${
        supplierId ? `?supplier_id=${supplierId}` : ''
      }`,
      {
        cancelToken: cancelToken?.token || '',
      }
    )

    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchBrandAuditData = async (brandId, cancelToken) => {
  try {
    const response = await axios.get(
      `${apiConfig.api.brand}brand_audits/${brandId}`,
      {
        cancelToken: cancelToken?.token || '',
      }
    )
    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchTargetSetupAuditData = async (
  supplierId,
  auditType,
  cancelToken
) => {
  try {
    const response = await axios.get(
      `${apiConfig.api.supplier}${auditType}/${supplierId}`,
      {
        cancelToken: cancelToken?.token || '',
      }
    )
    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchCababilityDetails = async (
  supplierId,
  manufacturingTypeId
) => {
  try {
    const response = await axios.get(
      `${
        apiConfig.api.external
      }mvs_capability_details/${supplierId}/${null}/${manufacturingTypeId}`
    )
    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchGTSPDetails = async (supplierId, locationId) => {
  try {
    const response = await axios.get(
      `${apiConfig.api.external}legacy_gtsq/${supplierId}/${locationId}`
    )
    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchLocationAuditData = async (
  locationId,
  supplierId,
  cancelToken
) => {
  try {
    const response = await axios.get(
      `${apiConfig.api.location}audits/${locationId}${
        supplierId ? `?supplier_id=${supplierId}` : ''
      }`,
      {
        cancelToken: cancelToken?.token || '',
      }
    )

    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchLocationAuditDataFromVMM = async (supplierId, locationId) => {
  const url = `${apiConfig.api.external}legacy_location_history/${locationId}?${
    supplierId ? `supplier_id=${supplierId}` : ''
  }`
  // const url = `${apiConfig.api.external}legacy_location_history/334163?${
  //   supplierId ? `supplier_id=67768` : ''
  // }`

  try {
    const response = await axios.get(url)
    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchLocationDocuments = async (locationId) => {
  try {
    const response = await axios.get(
      `${apiConfig.api.location}supply_chain_certs/${locationId}`
    )

    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchPOLAgreementAuditData = async (supplierId) => {
  try {
    const response = await axios.get(
      `${apiConfig.api.supplier}pol_agreement_audits/${supplierId}`
    )
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchCOIAuditData = async (supplierId) => {
  try {
    const response = await axios.get(
      `${apiConfig.api.supplier}coi_audits/${supplierId}`
    )
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchSCCAuditData = async (supplierId) => {
  try {
    const response = await axios.get(
      `${apiConfig.api.supplier}supply_chain_certs_audits/${supplierId}`
    )
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  }
}

export function resetRelationship(dispatch) {
  dispatch({ type: ACTIONS.TYPE.SUPPLIER_RELATIONSHIP_RESET })
}

export function getSupplierRelationship(supplierId) {
  if (supplierId) {
    const url = `${apiConfig.api.supplier}supplier_relationship?supplier_id=${supplierId}`
    return function (dispatch, getState) {
      const { supplier } = getState()
      if (
        supplier?.relationshipInfo?.loading ||
        supplier?.relationshipInfo?.error
      ) {
        return
      }
      dispatch({ type: ACTIONS.TYPE.SUPPLIER_RELATIONSHIP_INIT })
      return axios
        .get(url)
        .then((response) => {
          const data = response.data
          dispatch({ type: ACTIONS.TYPE.SUPPLIER_RELATIONSHIP_INFO, data })
        })
        .catch((error) => {
          dispatch({ type: ACTIONS.TYPE.SUPPLIER_RELATIONSHIP_FAIL, error })
        })
    }
  }
}

export function deleteLocation(locationId, supplierId, dispatch) {
  return axios
    .delete(
      `${apiConfig.api.location}location_delete/${supplierId}/${locationId}`
    )
    .then(() => {
      dispatch(getSupplierLocation(supplierId))
      dispatch(Notification(true, 'Location Deleted Successfully'))
    })
    .catch(() => {
      dispatch(Notification(true, 'Sorry, Request Failed', true))
    })
}

export function updateSupplierLocationRelationship(postData, dispatch) {
  return axios
    .put(`${apiConfig.api.location}location_relationship`, postData)
    .then(() => {
      dispatch(getSupplierLocation(postData?.supplier_id))
      dispatch(Notification(true, 'Relationship Updated Successfully'))
    })
    .catch(() => {
      dispatch(Notification(true, 'Sorry, Request Failed', true))
    })
}
